.container>h3{
  text-align: center;

} 

.draw{
  width: 220px;
  height: 120px;
  background-color: rgb(45, 114, 131);
  border-radius: 10px;
  margin-top: 25px;
  
}
.ic{
  width: 35px;
  height: 35px;
}
.dh{
  padding: 10px;
  cursor:pointer
  
}
.dh:hover{
background-color: rgb(21, 58, 113,1);
  color: aliceblue;
  border-radius: 10px;
}

.di1{
  padding: 10px;
}
.di1:hover{
  background-color: rgb(21, 58, 113,1);
  border-radius: 10px;
  color: aliceblue;
}

.cn{
  font-weight: 700;
}
.usr{
  cursor: pointer;
}
.bgit{
  background-image:url('./asset/ticket.png');
 background-size: cover;
 width: 89px;
 height: 50px;
 text-align: center;

}
.bgit>span{

 position: relative;
 top: 35%;
 font-size: 14px;
 color:#130404;
 font-weight: 600;
}
.bgit2{
  background-image:url('./asset/ticket.png');
 background-size: cover;
 width: 300px;
 height: 200px;
 text-align: center;

}
.bgit2>span{
  vertical-align: middle;
 position: relative;
 top: 42%;
 font-size: 35px;
 color:#130404;
 font-weight: 600;
}

.scap{
  height: 160px;
  overflow-y: scroll;
}
.scap::-webkit-scrollbar {
  width: 3px;
}
